import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Loadable from 'components/Loader/Loadable';

// project imports
import MainLayout from 'layout/MainLayout';

// login option 3 routing
const DashboardHome = Loadable(lazy(() => import('views/dashboard/Home/home')));
const DashboardHomeProjectDetails = Loadable(lazy(() => import('views/dashboard/Home/projectdetails')));
const DashboardAutomatePipelines = Loadable(lazy(() => import('views/dashboard/Automate/pipelines')));
const DashboardAutomateReusableComponents = Loadable(lazy(() => import('views/dashboard/Automate/reusablecomponents')));
const DashboardAutomateComponentDetails = Loadable(lazy(() => import('views/dashboard/Automate/componentdetails')));
const DashboardAssureQualityAssurance = Loadable(lazy(() => import('views/dashboard/Assure/qualityassurance')));
const DashboardAssureMRODashboard = Loadable(lazy(() => import('views/dashboard/Assure/mrodashboard')));
const DashboardAssureMROResult = Loadable(lazy(() => import('views/dashboard/Assure/mroresult')));
const DashboardAssureQualityResult = Loadable(lazy(() => import('views/dashboard/Assure/qualityresult')));
const DashboardGovernModelExplainations = Loadable(lazy(() => import('views/dashboard/Govern/modelexplainations')));
const DashboardGovernModelExplainationsDetails = Loadable(lazy(() => import('views/dashboard/Govern/governinsights')));
const DashboardMonitorModelDrift = Loadable(lazy(() => import('views/dashboard/Monitor/modeldrift')));
const DashboardMonitorDataDrift = Loadable(lazy(() => import('views/dashboard/Monitor/datadrift')));
const DashboardMonitorServiceHealth = Loadable(lazy(() => import('views/dashboard/Monitor/servicehealth')));
const DashboardMonitorModelDetails = Loadable(lazy(() => import('views/dashboard/Monitor/modelinsights')));
const DashboardMonitorDriftDetails = Loadable(lazy(() => import('views/dashboard/Monitor/driftdetails')));
const DashboardMonitorFeatureDetails = Loadable(lazy(() => import('views/dashboard/Monitor/featureDetails')))
const DashboardUserManagement = Loadable(lazy(() => import('views/dashboard/UserManagement/userMangement')));
const DashboardProfileSettings = Loadable(lazy(() => import('views/settings/setting')));
const PageNotFound = Loadable(lazy(() => import('views/not_found/404')));

//-----------------------|| AUTHENTICATION ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/home',
                '/dashboard/automate',
                '/dashboard/assure/quality-assurance',
                '/dashboard/assure/mro-dashboard',
                '/dashboard/assure/mro-result',
                '/dashboard/assure/quality-result',
                '/dashboard/govern/model-explainations',
                '/dashboard/monitor/model-drift',
                '/dashboard/monitor/data-drift',
                '/dashboard/monitor/service-health',
                '/dashboard/userManagement',
                '/dashboard/profile/settings'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/dashboard/home" component={DashboardHome} />
                    <Route exact path="/dashboard/home/:projectId" component={DashboardHomeProjectDetails} />
                    <Route exact path="/dashboard/automate/pipelines" component={DashboardAutomatePipelines} />
                    <Route exact path="/dashboard/automate/reusable-components" component={DashboardAutomateReusableComponents} />
                    <Route
                        exact
                        path="/dashboard/automate/reusable-components/:componentName"
                        component={DashboardAutomateComponentDetails}
                    />
                    <Route exact path="/dashboard/assure/quality-assurance" component={DashboardAssureQualityAssurance} />
                    <Route exact path="/dashboard/assure/mro-dashboard" component={DashboardAssureMRODashboard} />
                    <Route exact path="/dashboard/assure/mro-dashboard/mro-result" component={DashboardAssureMROResult} />
                    <Route exact path="/dashboard/assure/mro-dashboard/mro-result/:modelID" component={DashboardAssureMROResult} />
                    <Route exact path="/dashboard/assure/quality-result" component={DashboardAssureQualityResult} />
                    <Route exact path="/dashboard/govern/model-explainations" component={DashboardGovernModelExplainations} />
                    <Route
                        exact
                        path="/dashboard/govern/model-explainations/:modelName"
                        component={DashboardGovernModelExplainationsDetails}
                    />
                    <Route exact path="/dashboard/monitor/model-drift" component={DashboardMonitorModelDrift} />
                    <Route exact path="/dashboard/monitor/data-drift" component={DashboardMonitorDataDrift} />
                    <Route exact path="/dashboard/monitor/service-health" component={DashboardMonitorServiceHealth} />
                    <Route exact path="/dashboard/monitor/model-drift/:modelName" component={DashboardMonitorModelDetails} />
                    <Route exact path="/dashboard/monitor/data-drift/:datasetName" component={DashboardMonitorDriftDetails} />
                    <Route exact path="/dashboard/monitor/data-drift/:datasetName/:featureName" component={DashboardMonitorFeatureDetails} />
                    <Route exact path="/dashboard/userManagement" component={DashboardUserManagement} />
                    <Route exact path="/dashboard/profile/settings" component={DashboardProfileSettings} />
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
