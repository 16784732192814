import PropTypes from 'prop-types';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import menuItems from 'menu-items';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
// import { CustomSelect } from 'components/Dropdown/Dropdown';
import { Container, Box, Backdrop, Stack, Typography } from '@mui/material';
import { colors } from 'theme/variables';
import { Header } from './Navbar';
import Sidebar from './Sidebar';

const drawerWidth = 280;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const MainLayout = ({ children }) => {
    const [role, setRole] = React.useState('');
    const [name, setName] = React.useState('');
    const open = true;

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    React.useEffect(() => {
        const token = localStorage.getItem('authToken');

        axios.get('/user/profile', { headers: { Authorization: `Bearer ${token}` } }).then(response => {
            console.log(response.data);
            setName(response.data['FIRST_NAME'] + ' ' + response.data['LAST_NAME']);
            setRole(response.data['USER_ROLE']);
        });
    }, []);

    return (
        <Box sx={{ display: 'flex', background: colors.grey[100] }}>
            {name.length > 0 && role.length > 0 ? (
                <>
                    <Header open={open} drawerWidth={drawerWidth} state={{ name, role }} />
                    <Sidebar open={open} menuItems={menuItems} state={{ name, role }}></Sidebar>
                    <Container maxWidth="lg">
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                width: '100%',
                                paddingTop: 2,
                                paddingBottom: 6,
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                background: colors.grey[100]
                            }}
                        >
                            <DrawerHeader />
                            <div>{children}</div>
                        </Box>
                    </Container>
                </>
            ) : (
                <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={open}>
                    <Stack direction={'column'} alignItems={'center'} spacing={2}>
                        <Typography
                            variant={'body2'}
                            sx={{
                                color: colors.common.light,
                                fontWeight: 600
                            }}
                        >
                            Starting the Application...
                        </Typography>
                        <CircularProgress color="inherit" />
                    </Stack>
                </Backdrop>
            )}

            {/* <Main open={leftDrawerOpened}> */}
            {/* breadcrumb */}

            {/* </Main> */}
        </Box>
    );
};
MainLayout.propTypes = {
    children: PropTypes.node
};

export default MainLayout;
