export const palette = {
    default: {
        lighter: '#637381',
        light: '#454F5B',
        main: '#212B36',
        dark: '#161C24',
        
    },
    primary: {
        lighter: '#6694b6',
        light: '#33709e',
        main: '#004479',
        dark: '#00355e',
        darker: '#002643'
    },
    secondary: {
        lighter: '#D6E4FF',
        light: '#84A9FF',
        main: '#3366FF',
        dark: '#1939B7',
        darker: '#091A7A'
    },
    info: {
        lighter: '#CAFDF5',
        light: '#61F3F3',
        main: '#00B8D9',
        dark: '#006C9C',
        darker: '#003768'
    },
    success: {
        lighter: '#D8FBDE',
        light: '#86E8AB',
        main: '#36B37E',
        dark: '#1B806A',
        darker: '#0A5554'
    },
    warning: {
        lighter: '#FFF5CC',
        light: '#FFD666',
        main: '#FFAB00',
        dark: '#B76E00',
        darker: '#7A4100'
    },
    error: {
        lighter: '#FFE9D5',
        light: '#FFAC82',
        main: '#FF5630',
        dark: '#B71D18',
        darker: '#7A0916'
    },
    grey: {
        100: '#F9FAFB',
        200: '#F4F6F8',
        300: '#DFE3E8',
        400: '#C4CDD5',
        500: '#919EAB',
        600: '#637381',
        700: '#454F5B',
        800: '#212B36',
        900: '#161C24'
    },
    common: {
        light: '#FFFFFF',
        dark: '#000000'
    },
    text: {
        light: { primary: '#212B36', secondary: '#637381', disabled: '#919EAB' },
        dark: { primary: '#FFFFFF', secondary: '#919EAB', disabled: '#637381' }
    }
};
