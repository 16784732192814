export const colors = {
    primary: {
        lighter: '#6694b6',
        light: '#33709e',
        main: '#004479',
        dark: '#00355e',
        darker: '#002643'
    },
    secondary: {
        lighter: '#D6E4FF',
        light: '#84A9FF',
        main: '#3366FF',
        dark: '#1939B7',
        darker: '#091A7A'
    },
    info: {
        lighter: '#CAFDF5',
        light: '#61F3F3',
        main: '#00B8D9',
        dark: '#006C9C',
        darker: '#003768'
    },
    success: {
        lighter: '#D8FBDE',
        light: '#86E8AB',
        main: '#36B37E',
        dark: '#1B806A',
        darker: '#0A5554'
    },
    warning: {
        lighter: '#FFF5CC',
        light: '#FFD666',
        main: '#FFAB00',
        dark: '#B76E00',
        darker: '#7A4100'
    },
    error: {
        lighter: '#FFE9D5',
        light: '#FFAC82',
        main: '#FF5630',
        dark: '#B71D18',
        darker: '#7A0916'
    },
    grey: {
        100: '#F9FAFB',
        200: '#F4F6F8',
        300: '#DFE3E8',
        400: '#C4CDD5',
        500: '#919EAB',
        600: '#637381',
        700: '#454F5B',
        800: '#212B36',
        900: '#161C24'
    },
    common: {
        light: '#FFFFFF',
        dark: '#000000',
        icon: '#194c30'
    },
    transperant: {
        primary: {
            8: 'rgba(32, 101, 209, 0.08)',
            12: 'rgba(32, 101, 209, 0.12)',
            16: 'rgba(32, 101, 209, 0.16)',
            24: 'rgba(32, 101, 209, 0.24)',
            32: 'rgba(32, 101, 209, 0.32)',
            48: 'rgba(32, 101, 209, 0.48)'
        },
        secondary: {
            8: 'rgba(51, 102, 255, 0.08)',
            12: 'rgba(51, 102, 255, 0.12)',
            16: 'rgba(51, 102, 255, 0.16)',
            24: 'rgba(51, 102, 255, 0.24)',
            32: 'rgba(51, 102, 255, 0.32)',
            48: 'rgba(51, 102, 255, 0.48)'
        },
        info: {
            8: 'rgba(0, 184, 217, 0.08)',
            12: 'rgba(0, 184, 217, 0.12)',
            16: 'rgba(0, 184, 217, 0.16)',
            24: 'rgba(0, 184, 217, 0.24)',
            32: 'rgba(0, 184, 217, 0.32)',
            48: 'rgba(0, 184, 217, 0.48)'
        },
        success: {
            8: 'rgba(54, 179, 126, 0.08)',
            12: 'rgba(54, 179, 126, 0.12)',
            16: 'rgba(54, 179, 126, 0.16)',
            24: 'rgba(54, 179, 126, 0.24)',
            32: 'rgba(54, 179, 126, 0.32)',
            48: 'rgba(54, 179, 126, 0.48)'
        },
        warning: {
            8: 'rgba(255, 171, 0, 0.08)',
            12: 'rgba(255, 171, 0, 0.12)',
            16: 'rgba(255, 171, 0, 0.16)',
            24: 'rgba(255, 171, 0, 0.24)',
            32: 'rgba(255, 171, 0, 0.32)',
            48: 'rgba(255, 171, 0, 0.48)'
        },
        error: {
            8: 'rgba(255, 86, 48, 0.08)',
            12: 'rgba(255, 86, 48, 0.12)',
            16: 'rgba(255, 86, 48, 0.16)',
            24: 'rgba(255, 86, 48, 0.24)',
            32: 'rgba(255, 86, 48, 0.32)',
            48: 'rgba(255, 86, 48, 0.48)'
        },
        grey: {
            8: 'rgba(145, 158, 171, 0.08)',
            12: 'rgba(145, 158, 171, 0.12)',
            16: 'rgba(145, 158, 171, 0.16)',
            24: 'rgba(145, 158, 171, 0.24)',
            32: 'rgba(145, 158, 171, 0.32)',
            48: 'rgba(145, 158, 171, 0.48)',
            80: 'rgba(145, 158, 171, 0.80)'
        },
        black: {
            8: 'rgba(0, 0, 0, 0.08)',
            12: 'rgba(0, 0, 0, 0.12)',
            16: 'rgba(0, 0, 0, 0.16)',
            24: 'rgba(0, 0, 0, 0.24)',
            32: 'rgba(0, 0, 0, 0.32)',
            48: 'rgba(0, 0, 0, 0.48)'
        },
        white: {
            8: 'rgba(255, 255, 255, 0.08)',
            12: 'rgba(255, 255, 255, 0.12)',
            16: 'rgba(255, 255, 255, 0.16)',
            24: 'rgba(255, 255, 255, 0.24)',
            32: 'rgba(255, 255, 255, 0.32)',
            48: 'rgba(255, 255, 255, 0.48)'
        },
        gradient: {
            primary: 'linear-gradient(135deg, rgba(118,176,241, 0.2) 0%, rgba(32, 101, 209, 0.2) 100%)',
            secondary: 'linear-gradient(135deg, #84A9FF 0%, #3366FF 100%)',
            info: 'linear-gradient(135deg, #61F3F3 0%, #00B8D9 100%)',
            success: 'linear-gradient(135deg, #86E8AB 0%, #36B37E 100%)',
            warning: 'linear-gradient(135deg, #FFD666 0%, #FFAB00 100%)',
            error: 'linear-gradient(135deg, #FFAC82 0%, #FF5630 100%)'
        }
    },
    gradient: {
        primary: 'linear-gradient(135deg, #76B0F1 0%, #2065D1 100%)',
        secondary: 'linear-gradient(135deg, #84A9FF 0%, #3366FF 100%)',
        info: 'linear-gradient(135deg, #61F3F3 0%, #00B8D9 100%)',
        success: 'linear-gradient(135deg, #86E8AB 0%, #36B37E 100%)',
        warning: 'linear-gradient(135deg, #FFD666 0%, #FFAB00 100%)',
        error: 'linear-gradient(135deg, #FFAC82 0%, #FF5630 100%)'
    },
    text: {
        light: { primary: '#212B36', secondary: '#637381', disabled: '#919EAB' },
        dark: { primary: '#FFFFFF', secondary: '#919EAB', disabled: '#637381' }
    }
};

export const shadows = {
    card: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
    dialog: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
    dropdown: '0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
    z1: '0px 1px 2px rgba(145, 158, 171, 0.16)',
    z4: '0px 4px 8px rgba(145, 158, 171, 0.16)',
    z8: '0px 8px 16px rgba(145, 158, 171, 0.16)',
    z12: '0px 12px 24px -4px rgba(145, 158, 171, 0.16)',
    z16: '0px 16px 32px -4px rgba(145, 158, 171, 0.16)',
    z20: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    z24: '0px 24px 48px rgba(145, 158, 171, 0.16)',
    primary: '0px 8px 16px rgba(32, 101, 209, 0.24)',
    secondary: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    info: '0px 8px 16px rgba(0, 184, 217, 0.24)',
    success: '0px 8px 16px rgba(54, 179, 126, 0.24)',
    warning: '0px 8px 16px rgba(255, 171, 0, 0.24)',
    error: '0px 8px 16px rgba(255, 86, 48, 0.24)'
};
