import PropTypes from 'prop-types';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Avatar, Skeleton, Stack } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, userRole }) => {
    // const theme = useTheme();
    // console.log(item, userRole);
    // menu list collapse & items
    const items = item.children?.map(menu => {
        switch (menu.type) {
            case 'collapse':
                if (menu.rbac.includes(userRole)) {
                    return <NavCollapse key={menu.id} menu={menu} level={1} userRole={userRole} />;
                }

            case 'item':
                if (menu.rbac.includes(userRole)) {
                    return <NavItem key={menu.id} item={menu} level={1} />;
                }

            default:
                return <></>;
        }
    });
    // console.log(items);
    return (
        <>
            {items}

            {/* group divider */}
            {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
