import React from 'react';
// import Loadable from 'components/Loader/Loadable';
import { Redirect, Switch } from 'react-router-dom';

// import MinimalLayout from 'layout/MinimalLayout';
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
// project imports
import config from 'config';
import axios from 'axios';
import constants from '../constants';

axios.defaults.baseURL = constants.API_URL_PROD;
// const PageNotFound = Loadable(lazy(() => import('views/not_found/404')));
//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    console.log('routes page reached');
    return (
        <Switch>
            <Redirect exact from="/" to={config.defaultPath} />
            <React.Fragment>
                {/* Routes for authentication pages */}
                <AuthenticationRoutes />
                {/* Routes for main layouts */}
                <MainRoutes />
                {/* <MinimalLayout>
                    <Route exact path="/404" component={PageNotFound} />
                </MinimalLayout> */}
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
