import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// routing
import ThemeConfig from './theme';
import Routes from './routes';
import { store } from 'store';
// import NavigationScroll from './layout/NavigationScroll';
//-----------------------|| APP ||-----------------------//

const App = () => {
    return (
        <Provider store={store}>
            <ThemeConfig>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </ThemeConfig>
        </Provider>
    );
};

export default App;
