import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
import AppLogo from 'assets/images/Logo/KenAI.png';
import CompanyLogo from 'assets/images/Logo/LTIMindtree.png';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(() => ({
    backgroundColor: 'rgba(255, 255, 255, 0)'
}));

export function Header(props) {
    return (
        <AppBar position="fixed" elevation={0}>
            <Toolbar>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    {/* <Typography variant={'h3'}>{header[currentPath]}</Typography> */}
                    <Box component="img" alt="Company logo." sx={{ height: '60px' }} src={CompanyLogo} />
                    <Box component="img" alt="App logo." sx={{ height: '30px' }} src={AppLogo} />
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
