import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { colors } from 'theme/variables';
// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';
// import config from 'config';

// assets
import { Circle } from 'phosphor-react';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const { pathname } = useLocation();
    const icon_size = 24;
    const weight = 'duotone';
    const Icon = item.icon;
    const itemIcon = item.icon ? (
        <Icon
            size={icon_size}
            weight={weight}
            color={pathname.includes(item.url) ? colors.primary.main : colors.grey[600]}
        />
    ) : (
        <Circle
            size={pathname.includes(item.url) ? 10 : 6}
            color={pathname.includes(item.url) ? colors.primary.main : colors.grey[500]}
            weight="fill"
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${item.url}`} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: '8px',
                mb: '4px',
                alignItems: 'center',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                '&:hover': {
                    backgroundColor: colors.transperant.grey[8],
                },
                '&.Mui-selected': {
                    backgroundColor: colors.transperant.primary[8]
                },
                padding: level > 1 ? '4px 12px 4px 16px' : '8px 12px 8px 16px'
            }}
            selected={pathname.includes(item.url)}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ marginRight: level > 1 ? '24px' : '16px', minWidth: 0, marginLeft: level > 1 ? '8px' : '0px' }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={'body2'}
                        sx={{ fontWeight: pathname.includes(item.url) ? 600 : 400 }}
                        color={
                            pathname.includes(item.url)
                                ? level > 1
                                    ? colors.grey[800]
                                    : 'primary'
                                : colors.grey[600]
                        }
                    >
                        {item.title}
                    </Typography>
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
