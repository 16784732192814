// assets
import { Gear, SignOut ,UsersThree} from "phosphor-react";

// Icon Size
const icon_size = 18

// constant
const icons = {
    IconGear: <Gear size={icon_size} />,
    IconSignOut: <SignOut size={icon_size} />,
    IconUsersThree: <UsersThree size={icon_size} />
};

//-----------------------|| DASHBOARD MENU ITEMS SIDEBAR FOOTER ||-----------------------//

export const hyperlinks = [
    {
        id: 'setting',
        title: 'Settings',
        type: 'action',
        url: '/dashboard/profile/settings',
        icon: icons['IconGear']
    },
    {
        id: 'logout',
        title: 'Logout',
        type: 'action',
        url: '/auth/login',
        icon: icons['IconSignOut']
    },
    {
        id: 'admin',
        title: 'admin',
        type: 'action',
        url: '/auth/userManagement',
        icon: icons['UsersThree']
    }
];
