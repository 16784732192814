import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { Stack, Grid, Toolbar, Avatar, Box, Typography, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';

import { colors } from 'theme/variables';
import { CustomIconButton } from 'components/Button/IconButton';
import { Bell, Gear, SignOut, UsersThree } from 'phosphor-react';
import AppLogo from 'assets/images/Logo/KenAIWhite2.png';
import { Link } from 'react-router-dom';
// , CaretDown
// import axios from 'axios';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open, drawerwidth }) => ({
    zIndex: theme.zIndex.drawer + 1,
    // marginLeft: 200,
    // marginLeft: 57,
    // width: `calc(100% - ${57}px)`,
    // borderBottom: '1px solid #F2F2EF',
    backdropFilter: 'blur(6px)',
    backgroundColor: colors.primary.main,
    color: colors.common.light,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerwidth,
        width: `calc(100% - ${drawerwidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

export function Header(props) {
    // const [loading, setLoading] = React.useState(true);
    // const [currentPath, setcurrentPath] = React.useState([]);
    const {
        drawerWidth,
        state: { name, role }
    } = props;
    const icon_size = 20;
    const icon_color = colors.common.light;
    const weight = 'fill';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElSetting, setAnchorElSetting] = React.useState(null);
    const open = Boolean(anchorEl);
    const openSettings = Boolean(anchorElSetting);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed" elevation={0}>
            <Toolbar sx={{ padding: '0px 40px !important' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    {/* <Typography variant={'h3'}>{header[currentPath]}</Typography> */}
                    <Stack direction="row" alignItems={'center'} spacing={8}>
                        <Link to={`/dashboard/home`}>
                            <Box component="img" alt="App logo." sx={{ height: '30px' }} src={AppLogo} />
                        </Link>

                        <Typography variant="caption1" sx={{ color: colors.grey[100], fontWeight: 500 }}>
                            FAQ
                        </Typography>
                        <a
                            href={'https://kenai-lti.atlassian.net/wiki/spaces/KENAI/pages/1245185/KenAI+Data+Storage+Architecture'}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                        >
                            <Typography variant="caption1" sx={{ color: colors.grey[100], fontWeight: 500 }}>
                                Documentation
                            </Typography>
                        </a>

                        <Typography variant="caption1" sx={{ color: colors.grey[100], fontWeight: 500 }}>
                            Contact Us
                        </Typography>
                    </Stack>

                    <Stack direction="row" alignItems={'center'} spacing={1.5}>
                        {/* <CustomIconButton onCLick={() => localStorage.setItem('authToken', '')}>
                            <Link style={{ textDecoration: 'none' }} to={'/dashboard/userManagement'}>
                                <UsersThree size={icon_size} weight={weight} color={icon_color} />
                            </Link>
                        </CustomIconButton> */}

                        <CustomIconButton>
                            <Bell size={icon_size} weight={weight} color={icon_color} />
                        </CustomIconButton>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/dashboard/profile/settings', state: { name, role } }}>
                            <CustomIconButton
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openSettings ? 'settings' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openSettings ? 'true' : undefined}
                            >
                                <Gear size={icon_size} weight={weight} color={icon_color} />
                            </CustomIconButton>
                        </Link>

                        <CustomIconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar alt="Remy Sharp" />
                        </CustomIconButton>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0
                                    }
                                }
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem>
                                <Avatar /> My Account
                            </MenuItem>
                            <Divider />
                            <Link style={{ textDecoration: 'none' }} to={'/auth/login'}>
                                <MenuItem onCLick={() => localStorage.setItem('authToken', '')}>
                                    <ListItemIcon>
                                        <SignOut size={icon_size} weight={weight} color={colors.primary.dark} />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Link>
                        </Menu>
                    </Stack>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
