import { dashboard } from './dashboard';
import { hyperlinks } from './actionbuttons';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard],
    action: hyperlinks
};

export default menuItems;
