// assets
import { HouseLine, Brain, ListChecks, ShieldCheck, ChartBar, UsersThree } from 'phosphor-react';

// Icon Size
// const icon_size = 20;

// Weight
// const weight = 'regular';

// constant
const icons = {
    IconHome: HouseLine,
    IconRobot: Brain,
    IconTesttube: ListChecks,
    IconScales: ShieldCheck,
    IconWebcam: ChartBar,
    IconUserManagement: UsersThree
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//
export const action = {
    id: 'action',
    title: 'Action',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            type: 'item',
            url: '/dashboard/usermanagement',
            icon: icons['IconUserManagement'],
            breadcrumbs: false
        }
    ]
};

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            type: 'item',
            url: '/dashboard/home',
            icon: icons['IconHome'],
            rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Risk Officer', 'Business Analyst', 'No Access Role'],
            breadcrumbs: false
        },
        {
            id: 'automate',
            title: 'Automate',
            type: 'collapse',
            url: '/dashboard/automate',
            icon: icons['IconRobot'],
            rbac: ['Admin', 'ML Engineer', 'Data Scientist'],
            breadcrumbs: false,
            children: [
                {
                    id: 'pipelines',
                    title: 'Pipelines',
                    type: 'item',
                    url: '/dashboard/automate/pipelines',
                    rbac: ['Admin', 'ML Engineer'],
                    breadcrumbs: false
                },
                {
                    id: 'reusable-components',
                    title: 'Reusable Components',
                    type: 'item',
                    url: '/dashboard/automate/reusable-components',
                    rbac: ['Admin', 'ML Engineer', 'Data Scientist'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'assure',
            title: 'Assure',
            url: '/dashboard/assure',
            icon: icons['IconTesttube'],
            rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Risk Officer'],
            type: 'collapse',
            children: [
                {
                    id: 'quality-assurance',
                    title: 'Quality Assurance',
                    type: 'item',
                    url: '/dashboard/assure/quality-assurance',
                    rbac: ['Admin', 'ML Engineer', 'Data Scientist'],
                    breadcrumbs: false
                },
                {
                    id: 'mro-dashboard',
                    title: 'MRO Dashboard',
                    type: 'item',
                    url: '/dashboard/assure/mro-dashboard',
                    rbac: ['Admin', 'ML Engineer', 'Risk Officer'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'govern',
            title: 'Govern',
            url: '/dashboard/govern',
            icon: icons['IconScales'],
            rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Business Analyst'],
            type: 'collapse',
            children: [
                {
                    id: 'model-explainations',
                    title: 'Model Explanations',
                    type: 'item',
                    url: '/dashboard/govern/model-explainations',
                    rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Business Analyst'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'monitor',
            title: 'Monitor',
            url: '/dashboard/monitor',
            icon: icons['IconWebcam'],
            rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Business Analyst'],
            type: 'collapse',
            children: [
                {
                    id: 'model-drift',
                    title: 'Model Drift',
                    type: 'item',
                    url: '/dashboard/monitor/model-drift',
                    rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Business Analyst'],
                    breadcrumbs: false
                },
                {
                    id: 'data-drift',
                    title: 'Data Drift',
                    type: 'item',
                    url: '/dashboard/monitor/data-drift',
                    rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Business Analyst'],
                    breadcrumbs: false
                },
                {
                    id: 'service-health',
                    title: 'Service Health',
                    type: 'item',
                    url: '/dashboard/monitor/service-health',
                    rbac: ['Admin', 'ML Engineer', 'Data Scientist', 'Business Analyst'],
                    breadcrumbs: false
                }
            ]
        }
    ]
};
