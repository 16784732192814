import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Loadable from 'components/Loader/Loadable';

// project imports
import AuthLayout from '../layout/AuthLayout';

// login option 3 routing
const LoginAuthentication = Loadable(lazy(() => import('views/login/authentication')));
const CreateAccount = Loadable(lazy(() => import('views/signup/createaccount')));
const ForgotPassword = Loadable(lazy(() => import('views/forgot_password/forgot_password')));
const ResetPassword = Loadable(lazy(() => import('views/reset_password/reset_password')));

//-----------------------|| AUTHENTICATION ROUTING ||-----------------------//

const AuthenticationRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/auth/login', '/auth/signup', '/auth/forgot_password', '/auth/reset_password']}>
            <AuthLayout>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/auth/login" component={LoginAuthentication} />
                    <Route exact path="/auth/signup" component={CreateAccount} />
                    <Route exact path="/auth/forgot_password" component={ForgotPassword} />
                    <Route exact path="/auth/reset_password" component={ResetPassword} />
                </Switch>
            </AuthLayout>
        </Route>
    );
};

export default AuthenticationRoutes;
