import * as React from 'react';
import { styled } from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';
// import { useTheme } from '@mui/material/styles';
import { UsersThree } from 'phosphor-react';
import { colors } from 'theme/variables';
import MenuList from './MenuList';
import { Link, useRouteMatch } from 'react-router-dom';
import { Stack, Grid, Avatar, Box, Typography, ListItemIcon, ListItemText, ListItemButton, Skeleton } from '@mui/material';

const drawerWidth = 280;

const openedMixin = theme => ({
    width: drawerWidth,
    borderRight: '1px dashed rgba(145, 158, 171, 0.24)',
    // padding: '0px 16px',
    background: colors.grey[100],
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        width: 5
    },
    '&::-webkit-scrollbar-track': {
        background: 'transperant'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#C4CDD5',
        borderRadius: '10px'
    }
});

const closedMixin = theme => ({
    border: 'none',
    background: colors.grey[100],
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme, open }) => ({
    display: 'flex',
    // flexDirection:'column',
    // alignItems: 'center',
    marginTop: '65px',
    justifyContent: 'flex-start',
    padding: '24px 16px 16px',
    // necessary for content to be below app bar
    ...(open &&
        {
            // padding: theme.spacing(0, 2)
        })
    // ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    border: 'none',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'scroll',

    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = props => {
    // const [username, setUsername] = React.useState('');
    const {
        open,
        menuItems,
        state: { name, role }
    } = props;
    const icon_size = 24;
    const url = useRouteMatch();
    const selection = url.path.split('/').slice(-1)[0];
    const icon_color = selection === 'userManagement' ? colors.primary.main : colors.grey[700];
    const weight = 'duotone';

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader open={open}>
                <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/dashboard/profile/settings', state: { name, role } }}>
                            <ListItemButton
                                sx={{
                                    padding: '10px 20px',
                                    borderRadius: '12px',
                                    border: 'none',
                                    alignItems: 'left',
                                    width: '100%',
                                    backgroundColor: colors.transperant.grey[12],
                                    '&:hover': {
                                        backgroundColor: colors.transperant.grey[24]
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: colors.transperant.primary[16]
                                    }
                                }}
                                selected={selection === 'settings'}
                            >
                                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                    <Avatar alt="Remy Sharp" />
                                    <Stack direction="column" spacing={0.1}>
                                        <Typography sx={{ color: colors.grey[800], fontWeight: 600 }} variant={'subtitle2'}>
                                            {name ? name : <Skeleton width={100} />}
                                        </Typography>
                                        <Typography sx={{ color: colors.grey[600], fontWeight: 400 }} variant={'body2'}>
                                            {role ? role : <Skeleton width={50} />}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </ListItemButton>
                        </Link>
                    </Grid>
                </Grid>
            </DrawerHeader>

            <Box sx={{ padding: '0px 16px' }}>
                <MenuList userRole={role} />
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            {/* <Typography sx={{ color: colors.grey[600], fontWeight: 400 }} variant={'body2'}>
                    Leveraging
                </Typography>
                <img src={microsoftazure} alt="azure logo" height="20px" width={'120px'} /> */}
            {/* <CustomIconButton onCLick={() => localStorage.setItem('authToken', '')}>
                    <Link style={{ textDecoration: 'none' }} to={'/dashboard/userManagement'}>
                    <Typography sx={{ color: colors.grey[900], fontWeight: 400 }} variant={'body2'}>
                      <UsersThree size={icon_size} weight={weight} color={icon_color} /> 
                        User Management
                        </Typography>
                        
                    </Link>
                </CustomIconButton> */}
            {role === 'Admin' ? (
                <Link style={{ textDecoration: 'none' }} to={'/dashboard/userManagement'}>
                    <ListItemButton
                        sx={{
                            borderRadius: '8px',
                            margin: '0px 16px 16px 16px',
                            alignItems: 'center',
                            backgroundColor: 'inherit',
                            '&:hover': {
                                backgroundColor: colors.transperant.grey[8]
                            },
                            '&.Mui-selected': {
                                backgroundColor: colors.transperant.primary[8]
                            },
                            padding: '8px 12px 8px 16px'
                        }}
                        selected={selection === 'userManagement'}
                    >
                        <ListItemIcon sx={{ marginRight: '16px', minWidth: 0, marginLeft: '0px' }}>
                            <UsersThree size={icon_size} weight={weight} color={icon_color} />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant={'body2'}
                                    sx={{ fontWeight: selection === 'userManagement' ? 600 : 400 }}
                                    color={selection === 'userManagement' ? 'primary' : colors.grey[600]}
                                >
                                    User Management
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </Link>
            ) : (
                <></>
            )}
        </Drawer>
    );
};

export default Sidebar;
