import { colors } from './variables';

export const typography = {
    fontFamily: ['Public Sans', 'sans-serif'].join(','),

    h6: {
        fontWeight: 700,
        color: colors.primary.dark,
        fontSize: '18px',
        lineHeight: '28px'
    },
    h5: {
        fontSize: '20px',
        lineHeight: '30px',
        color: colors.primary.dark,
        letterSpacing: '-0.02em',
        fontWeight: 700
    },
    h4: {
        fontSize: '24px',
        lineHeight: '36px',
        color: colors.primary.dark,
        letterSpacing: '-0.018em',
        fontWeight: 700
    },
    h3: {
        fontSize: '32px',
        lineHeight: '48px',
        color: colors.primary.main,
        letterSpacing: '-0.02em',
        fontWeight: 700
    },
    h2: {
        fontSize: '48px',
        lineHeight: '64px',
        color: '#090B37',
        letterSpacing: '-0.02em',
        fontWeight: 800
    },
    h1: {
        fontSize: '64px',
        lineHeight: '80px',
        color: '#090B37',
        letterSpacing: '-0.02em',
        fontWeight: 800
    },
    subtitle1: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        color: colors.primary.dark
    },
    subtitle2: {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 400,
        color: colors.primary.dark
    },
    caption: {
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 400
    },
    body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px'
    },
    body2: {
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '-0.02em',
        color: colors.grey[800],
        fontWeight: 400
    },
    overline: {
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 700
    },
    button: {
        textTransform: 'capitalize',
        fontWeight: 600,
        color: '#FFFFFF',
        fontSize: '14px'
    }
};
