import PropTypes from 'prop-types';
import * as React from 'react';
import { Grid, Box, Paper, CssBaseline, Stack, Typography } from '@mui/material';
import rightBackground from 'assets/images/Login/sideBackground.png';
import assure from 'assets/images/Login/assure.svg';
import automate from 'assets/images/Login/automate.svg';
import monitor from 'assets/images/Login/monitor.svg';
import govern from 'assets/images/Login/govern.svg';
import Carousel from 'react-material-ui-carousel';
// import Slider from 'react-slick';
import { colors } from 'theme/variables';
import { Header } from './Navbar';

function Item(props) {
    return (
        <Stack direction="column" sx={{ paddingLeft: 2, paddingRight: 2 }} spacing={0}>
            <img src={props.item.illustration} alt="illustration" height="100px" width={'750px'} />
            {/* <Typography sx={{ fontWeight: 'bold', justifyContent: 'center', color: '#ffffff' }} variant={'h4'}>
                {props.item.name}
            </Typography> */}
        </Stack>
    );
}

const AuthLayout = ({ children }) => {
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };
    var items = [
        {
            illustration: assure
        },
        {
            illustration: automate
        },
        {
            illustration: govern
        },
        {
            illustration: monitor
        }
        // {
        //     name: 'Generate Report',
        //     description: 'Probably the most random thing you have ever seen!',
        //     illustration: DataReports
        // }
    ];
    return (
        <Box sx={{ display: 'flex' }}>
            <Header />

            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={0}
                    sm={0}
                    md={7}
                    sx={{
                        backgroundImage: `url(${rightBackground})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            // justifyContent: 'center',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            paddingTop: '90px',
                            height: '100%'
                        }}
                    >
                        {/* <Slider {...settings}>
                            {items.map((item, i) => (
                                <Item key={i} item={item} />
                            ))}
                        </Slider> */}
                        <Carousel
                            interval={'10000'}
                            // IndicatorIcon={<IconLine />}
                            indicatorIconButtonProps={{
                                style: {
                                    paddingLeft: '5px', // 1
                                    paddingRight: '5px', // 1
                                    color: 'white' // 3
                                }
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    color: 'black' // 2
                                    // fontSize: '15px',
                                    // backgroundColor: 'black',
                                }
                            }}
                        >
                            {items.map((item, i) => (
                                <Item key={i} item={item} />
                            ))}
                        </Carousel>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} sx={{ background: colors.grey[100] }} square>
                    <Box
                        sx={{
                            mx: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            height: '100%'
                        }}
                    >
                        <div>{children}</div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.node
};

export default AuthLayout;
