import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { colors } from 'theme/variables';

export function CustomIconButton(props) {
    // console.log(props);
    return (
        <IconButton
            sx={{
                color: props.color ? props.color : colors.grey[800],
                '&:hover': {
                    backgroundColor: colors.transperant.grey[8]
                },
                borderRadius: props.borderRadius ? props.borderRadius : '50px',
                height: props.height
            }}
            aria-label={props.label}
            onClick={props.onClick}
            component="span"
            disabled={props.disabled}
            disableRipple={props.disableRipple}
        >
            {props.children}
        </IconButton>
    );
}
