import PropTypes from 'prop-types';
import { useState } from 'react';
// import { useSelector } from 'react-redux';

// material-ui
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { colors } from 'theme/variables';
// project imports
import NavItem from '../NavItem';
import { useLocation } from 'react-router-dom';
// assets
import { CaretDown, CaretRight, Circle } from 'phosphor-react';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, userRole }) => {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // menu collapse & item
    const menus = menu.children?.map(item => {
        switch (item.type) {
            case 'collapse':
                if (item.rbac.includes(userRole)) {
                    return <NavCollapse key={item.id} menu={item} level={level + 1} />;
                }
            case 'item':
                if (item.rbac.includes(userRole)) {
                    return <NavItem key={item.id} item={item} level={level + 1} />;
                }
            default:
                return <></>;
        }
    });
    const icon_size = 24;
    const weight = 'duotone';
    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon size={icon_size} weight={weight} color={pathname.includes(menu.url) ? colors.primary.main : colors.grey[600]} />
    ) : (
        <Circle size={pathname.includes(menu.url) ? 8 : 6} weight="fill" fontSize={level > 0 ? 'inherit' : 'medium'} />
    );
    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: '8px',
                    mb: '4px',
                    alignItems: 'center',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    '&.Mui-selected': {
                        backgroundColor: colors.transperant.primary[8]
                    },
                    padding: '8px 12px 8px 16px'
                }}
                selected={pathname.includes(menu.url)}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ marginRight: '16px', minWidth: 0, justifyContent: 'center' }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            variant={'body2'}
                            sx={{ fontWeight: pathname.includes(menu.url) ? 600 : 400 }}
                            color={pathname.includes(menu.url) ? 'primary' : colors.grey[600]}
                        >
                            {menu.title}
                        </Typography>
                    }
                />
                {open ? (
                    <CaretDown size="16px" color={pathname.includes(menu.url) ? colors.primary.main : colors.grey[600]} />
                ) : (
                    <CaretRight size="16px" color={pathname.includes(menu.url) ? colors.primary.main : colors.grey[600]} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
